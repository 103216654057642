import {
    getSelectForMultipleEnum,
    getSelectForReferenceTypeId,
  } from "../services/add_investment_service";

export const universeMixins = {
    data() {
        return {
          dynamicUniverseCriteriaSectionUpdate: 0,
          changeStaticEntityLevel: "",
          selectentitylevel: "Legal Entity",
          universename: "",
          isCreateModeOn: true,
          comparsionvalue: 1,
          identifier: "",
          selected: true,
          selectedEntityName: new Set(),
          selectTab: "",
          selectEntitiesTabShow: true,
          uploadEntitiesTabShow: false,
          searchEntitiesName: "",
          entityNameStaticSearchPlaceholder: "Search by name or identifier",
          showLoader: true,
          selectIdentifier: [],
          entityTypeId: 1,
          showNotification: false,
          notificationMessage: "",
          notificationType: "",
          radioButtonforUniverseType: 0,
          radioButtonforDynamicUniverseType: 0,
          staticRequest: {},
          uploadingFiles: true,
          uploadingFileSpinner: false,
          uploadEntityWarning: false,
          uploadEntityError: false,
          uploadEntitySuccess: false,
          uploadEntityGetResponse: false,
          selectedMatchUploadEntity: [],
          uploadEntityMatched: 0,
          uploadEntityUnmatched: 0,
          uploadEntityTotal: 0,
          uploadEntityIds: [],
          uploadEntityHeader: "",
          uploadEntityFileName: "",
          uploadEntityList: [
            "You have selected the correct identifier.",
            "The identifiers match entities in the system",
          ],
    
          tabsContent: [
            {
              text: "Select Entities",
              id: "selectEntities",
              active: true,
            },
            {
              text: "Upload Entities",
              id: "uploadEntities",
              active: false,
            },
          ],
    
          entityNameHeader: [
            {
              fieldName: "entityname",
              text: "Entity Name",
              width: "100%",
            },
          ],
    
          entityNameRow: [],
    
          additionalSearchDataPoints: [],
    
          selectedEntityNameHeader: [
            {
              fieldName: "entityname",
              text: "Selected Entities",
              width: "70vh",
            },
            {
              fieldName: "selectentitylevelname",
              text: "",
              width: "23vh",
            },
            {
              fieldName: "actions",
              text: "",
              width: "7vh",
            },
          ],
    
          getIdentifierData: [
            {
              value: "1",
              text: "ISIN",
            },
            {
              value: "2",
              text: "FundID",
            },
          ],
    
          selectedEntityNameRow: [],
          searchField: "",
          additionalsearchdatapointname: "",
          additionalsearchdatapoint: "",
          additionalsearchoperator: "",
          additionalsearchvalue: "",
          additionalsearchvalueFrom: "",
          additionalsearchvalueTo: "",
          selectDataPointData: {},
          addSearchEnumId: null,
          addSearchDataTypeId: null,
          addSearchRefEntityTypeId: null,
          additionalSearchOperators: [],
          additionalSearchValues: [],
          getDynamicDataPoint2: [],
          duplicateAdditionalSearch: [],
          page: 1,
          pageSize: 20,
          firstItem: "",
          lastItem: "",
          totalItems: 0,
          paginationComponentKey: 0,
          additionalsearchPagination:false,
          objDupData:{},
          additionalsearchvaluetext:"",
          applyButtonArray:[],
          dateFormatArray: [],
          // ----------
          isAddCriteria: false,
          isExpanded: true,
          isEntityLevelSectionExpanded: true,
          dynamicUniverseEntityLevelSectionUpdate: 0,
          addEntityLevel: true,
          isSelectionValue: false,
          getselectedValue: [],
          selectedValueCount: 0,
          currentEntryLevel: "",
          EntryLevel: "",
          AddCriteria: "Add Criteria",
          AddCriteriaSelectedValue: "",
          AddCriteriaId: 0,
          AddCriteriaKey: false,
          toggleUniverseRadioButton: false,
          showSelectedValue: [],
          switchUniverseType: "",
          universeType: "Dynamic",
          currentUniverseType: "Dynamic",
          selectedDynamicEntityLevel: "",
          isselectedDynamicEntityLevel: false,
          isChecked: false,
          addCriteriaRemoveButton: false,
          editCriteriaRemoveButton: true,
          addedCriteriaTitle: "",
          showAddedCriteria: [],
          isdisablecheckbox: false,
          selectedOperator: "",
          dynamicEntityTypeId: 0,
          dataPointDescription: "",
          dynamicEnumId: null,
          dynamicDataTypeId: 0,
          dynamicDataTypeInput: "",
          dynamicInputLevel: false,
          getDynamicSelectionList: [],
          selectedDataPointList: [],
          DataPointList: [],
          sendDynamicRequest: [],
          checkedDynamicEntityLevel: [],
          getSelectionValue: [],
          referenceEntityTypeId: 0,
          searchDynamicEntityName: "",
          editSearchDynamicEntityName: "",
          sampleArray: [],
          dynamicInputValue: "",
          dynamicInputValueTo: "",
          checkedValue: {},
          switchUniverseTypeIndex: 0,
          EditDynamicResponse: [],
          isShowAddedCriteria: false,
          dataPointHeading: "",
          uploadedFile: [],
          getUniverseType: [
            {
              id: 1,
              lable: "Dynamic",
              text: "Add criteria to create a universe.",
            },
            {
              id: 2,
              lable: "Static",
              text: "Select individual entities.",
            },
          ],
          isEditModeOn: true,
          addedCriteriaDataListObject: [],
          betweenDateFrom: "",
          betweenDateTo: "",
          betweenFromInputValue: "",
          betweenToInputValue: "",
          index: 0,
          DynamicEntityLevelChange: false,
          DynamicEntityLevelIndex: 0,
          DynamicTemporaryArray: [],
          DynamicSelectionArray: [],
          EditDynamicTemporaryArray: [],
          DynamicTemporaryEntityId: 0,
          DynamicTemporaryEntryLevel: "",
          dynamicDataTypeZero: false,
          dynamicDataTypeZeroSelectionList: [],
          editUniverse: [],
          createUniverse: "",
          dynamicEditOperatorValue: "",
          isSelectedCriteria: true,
          isSelectedOperator: true,
          IsAdditionalSearchDefaultOperator:true,
          getOptionsforValue:[
            { text: "Equal to", value: "Equal to" },
            { text: "Not Equal to", value: "Not Equal to" },
            { text: "Greater than", value: "Greater than" },
            { text: "Less than", value: "Less than" },
            { text: "Greater than or equal to", value: "Greater than or equal to" },
            { text: "Less than or equal to", value: "Less than or equal to" }
          ],
          getOptionsforDate:[
            { text: "Is", value: "Is" },
            { text: "Is not", value: "Is not" },
            { text: "Before", value: "Before" },
            { text: "After", value: "After" },
            { text: "Between", value: "Between" }
          ],
          getOptionsforSingleSelection:[
            { text: "Equal", value: "Equal" },
            { text: "Not Equal", value: "Not Equal" },
            { text: "Like", value: "Like" }
          ]
        
        };
      },
    methods:{
        async editCheckType() {
          if (this.createUniverse == undefined && this.editUniverse.listid == undefined) {
              this.switchUniverseType = 1;
              this.universeType = "Dynamic";
            } else if (this.createUniverse == "Create Universe") {
              this.switchUniverseType = 1;
              this.universeType = "Dynamic";
            } else if (this.editUniverse != undefined) {
                let obj = {};
                (obj.listid = this.editUniverse.listid), (obj.isPublic = this.editUniverse.ispublic);
                await this.fetchEditUniverseList(obj);
                let response = await this.getEditUniverseList.data;
                if(response.statuscode == 200){
                  if (this.editUniverse.type == "Static") {
                    this.switchUniverseTypeIndex += 1;
                    this.switchUniverseType = 2;
                    this.universeType = "Static";
                    this.universename = this.editUniverse.listdescription;
                    this.isCreateModeOn = false;
                    let response = this.getEditUniverseList.data;
                    this.selectedEntityNameRow = response.result.definitions;
                    this.selectedEntityNameRow.forEach((item) => {
                      this.selectedEntityName.add(item);
                    });
                    if(this.editUniverse.iseditable == "true"){
                      this.dataMatchCheckBoxTrue();
                    }               
                  } 
                  else {
                    this.showLoader = true;
                    this.switchUniverseType = 1;
                    this.universeType = "Dynamic";
                    this.showAddedCriteria = [];
                    this.addEntityLevel = false;
                    this.isEditModeOn = this.editUniverse.iseditable;
                    this.universename = this.editUniverse.listdescription;
                    this.isCreateModeOn = false;
                    let response = this.getEditUniverseList.data;
                    this.EditDynamicResponse = response.result.filters;
                    this.dynamicEntityTypeId = this.EditDynamicResponse[0].entitytypeid;
          
                    await this.fetchDynamicDataPoint(this.dynamicEntityTypeId);
                    this.getDynamicSelectionList = this.getDynamicDataPoint;
                    this.editCriteriaRemoveButton = true;
          
                    if (this.dynamicEntityTypeId == 1) {
                      this.currentEntryLevel = "Legal Entity";
                      this.selectedDynamicEntityLevel = 1;
                    } else if (this.dynamicEntityTypeId == 2) {
                      this.currentEntryLevel = "Fund";
                      this.selectedDynamicEntityLevel = 2;
                    } else if (this.dynamicEntityTypeId == 3) {
                      this.currentEntryLevel = "Share Class";
                      this.selectedDynamicEntityLevel = 3;
                    } else if (this.dynamicEntityTypeId == 4) {
                      this.currentEntryLevel = "Dealing Currency";
                      this.selectedDynamicEntityLevel = 4;
                    }
                    this.DynamicEntityLevelIndex += 1;
                    this.switchUniverseTypeIndex += 1;
                    this.isEntityLevelSectionExpanded = false;
                    this.dynamicUniverseEntityLevelSectionUpdate++;
          
                    this.isAddCriteria = true;
                    this.AddCriteriaKey = true;
                    this.EditDynamicResponse.forEach(async (array) => {
                      let AddedCriteria = {
                        DatapointId: 0,
                        Title: "",
                        OperatorCondition: "In",
                        isSelectionValue: false,
                        dynamicInputLevel: false,
                        isSectionExpanded: false,
                        EnumId: 0,
                        DataPointTypeId: 0,
                        ReferenceTypeId: 0,
                        dynamicDataTypeId: 0,
                        selectedValueCount: 0,
                        getselectedValue: [],
                        getSelectionValue: [],
                        Value: [],
                      };
          
                      AddedCriteria.DatapointId = array.datapointid;
                      AddedCriteria.Title = array.datapointname;
                      AddedCriteria.OperatorCondition = array.operatorcondition;
                      AddedCriteria.isSelectionValue = true;
          
                      this.dynamicEnumId = array.enumid;
                      this.referenceEntityTypeId = array.referencetypeid;
                      this.dynamicDataTypeId = array.datapointtypeid;
          
                      AddedCriteria.EnumId = this.dynamicEnumId;
                      AddedCriteria.DataPointTypeId = this.dynamicDataTypeId;
                      AddedCriteria.ReferenceTypeId = this.referenceEntityTypeId;
                      AddedCriteria.dynamicInputLevel = this.dynamicInputLevel;
                      AddedCriteria.dynamicDataTypeId = this.dynamicDataTypeId;
          
                      if (this.dynamicEnumId == null) {
                        // input-field will be on
                        if (this.dynamicDataTypeId == 7) {
                          this.dynamicDataTypeZero = false;
                          this.dynamicInputLevel = false;
                          AddedCriteria.dynamicInputLevel = false;
          
                          let results = await getSelectForReferenceTypeId(
                            this.referenceEntityTypeId,
                            this.sampleArray
                          );
                          this.getSelectionValue = results.data;
                          AddedCriteria.getSelectionValue = this.getSelectionValue;
                          array.values.forEach((item) => {
                            this.getSelectionValue.forEach((result) => {
                              if (result.value == item) {
                                result.selected = true;
                                this.getselectedValue.push(result);
                              }
                            });
                          });
                          AddedCriteria.getselectedValue = this.getselectedValue;
                          this.getselectedValue.forEach((item) => {
                            let data = item;
          
                            this.showSelectedValue.push(data);
                          });
                          AddedCriteria.Value = this.showSelectedValue;
          
                          AddedCriteria.selectedValueCount = this.showSelectedValue.length;
                        } else if (this.dynamicDataTypeId == 0) {
                          this.dynamicDataTypeZero = true;
                          this.dynamicInputLevel = true;
                          AddedCriteria.dynamicInputLevel = true;
                          this.dynamicDataTypeZeroSelectionList = array.datapointgroups;
                          array.values.forEach((item) => {
                            this.getSelectionValue.forEach((result) => {
                              if (result.value == item) {
                                this.getselectedValue.push(result);
                              }
                            });
                          });
                          AddedCriteria.getselectedValue = this.getselectedValue;
                          this.getselectedValue.forEach((item) => {
                            let data = item;
          
                            this.showSelectedValue.push(data);
                          });
                          AddedCriteria.Value = this.showSelectedValue;
          
                          AddedCriteria.selectedValueCount = this.showSelectedValue.length;
                        } else {
                          this.dynamicInputLevel = true;
                          AddedCriteria.dynamicInputLevel = true;
                          if (
                            this.dynamicDataTypeId == 1 ||
                            this.dynamicDataTypeId == 2
                          ) {
                            AddedCriteria.getSelectionValue = this.getOptionsforValue;
                          } else if (
                            this.dynamicDataTypeId == 3 ||
                            this.dynamicDataTypeId == 4
                          ) {
                            AddedCriteria.getSelectionValue = this.getOptionsforDate;
                          } else if (
                            this.dynamicDataTypeId == 5 ||
                            this.dynamicDataTypeId == 6
                          ) {
                            AddedCriteria.getSelectionValue = this.getOptionsforSingleSelection;
                          }
          
                          if (array.operatorcondition == "Between") {
                            this.dynamicInputValue = array.values;
                            this.splitDateOrTime(this.dynamicInputValue);
                          }else{
                            this.dynamicInputValue = array.values[0];

                          }
                          let resultArray = [];
                          resultArray.text = this.dynamicInputValue;
                          resultArray.value = this.dynamicInputValue;
                          AddedCriteria.Value = [resultArray];
                          this.dynamicInputValue = "";
                          this.dynamicDataTypeZero = false;
                        }
                      }
                      // checkbox field will be on
                      else if (this.dynamicEnumId != null) {
                        this.dynamicInputLevel = false;
                        AddedCriteria.dynamicInputLevel = false;
                        let result = await getSelectForMultipleEnum(
                          this.dynamicEnumId,
                          this.sampleArray
                        );
                        this.getSelectionValue = result.data;
                        AddedCriteria.getSelectionValue = this.getSelectionValue;
                        array.values.forEach((item) => {
                          this.getSelectionValue.forEach((result) => {
                            if (result.value == item) {
                              result.selected = true;
                              this.getselectedValue.push(result);
                            }
                          });
                        });
                        AddedCriteria.getselectedValue = this.getselectedValue;
                        this.getselectedValue.forEach((item) => {
                          let data = item;
          
                          this.showSelectedValue.push(data);
                        });
                        AddedCriteria.Value = this.showSelectedValue;
          
                        AddedCriteria.selectedValueCount = this.showSelectedValue.length;
                      }
          
                      // this.getDynamicSelectionList = this.getDynamicSelectionList.filter(
                      //   (item) => item.name !== array.datapointname
                      // );
                      this.showAddedCriteria.push(AddedCriteria);
                      this.isSelectionValue = false;
                      this.selectedValueCount = 0;
                      this.getselectedValue = [];
                      this.showSelectedValue = [];
                      this.DynamicSelectionArray = this.getDynamicSelectionList;
                    });
          
                    this.showLoader = false;
                    return this.getDynamicSelectionList;
                  }
                }else{
                    this.showLoader = false
                    this.notificationMessage= response.message;
                    this.notificationType = "error";
                    this.notificationKey= "error-default";
                    this.showNotification = true;
                    setTimeout(() => {
                      this.showNotification = false;
                      if(this.editUniverse.iseditable != undefined){
                        this.$router.push({ name: "Reporting Universe" });
                      }
                    }, 2000);
                }
            }
            
          },
        // remove concatenation between two date or time
        splitDateOrTime(text) {
          let result = text;
            this.betweenDateFrom = result[0];
            this.betweenFromInputValue = result[0];
            this.betweenDateTo = result[1];
            this.betweenToInputValue = result[1];
      },
    }
}
<template>
  <div id="reportingViewUniverse">
    <div class="view-universe">
      <mds-layout-grid class="view-universe">
        <mds-row>
          <span>
            <router-link :to="{ name: getRouteDetails('/settings/universes') }">
              Universes
            </router-link>
          </span>
          <span class="view-universe-router">
            > View Universe: {{ viewUniverseName }}</span
          >
        </mds-row>
        <mds-row class="view-universe-heading">
          View Universe: {{ viewUniverseName }}
        </mds-row>

        <!-- universe name and universe type -->
        <mds-row>
          <mds-col :cols="4">
            <mds-row class="view-universe-name-heading">
              Universe Name
            </mds-row>
            <mds-row class="view-universe-name">
              {{ viewUniverseName }}
            </mds-row>
          </mds-col>
          <mds-col :cols="4">
            <mds-row class="view-universe-name-heading">
              Universe Type
            </mds-row>
            <mds-row class="view-universe-name">
              {{ universeTypes }}
            </mds-row>
          </mds-col>
        </mds-row>

        <mds-row class="view-universe-border"></mds-row>

        <mds-row class="universe-selected-data-type-heading">Entities</mds-row>

        <!-- heading for dynamic -->
        <mds-row
          class="view-universe-margin"
          v-if="this.universeTypes == 'Dynamic'"
        >
          <mds-col :cols="3" class="universe-criteria-name-column"
            >Criteria Name</mds-col
          >
          <mds-col class="universe-criteria-column">Criteria</mds-col>
        </mds-row>
        <!-- heading for static -->
        <mds-row
          v-if="this.universeTypes == 'Static'"
          class="view-universe-margin"
          >Imported Entities</mds-row
        >

        <!-- HTML for Entity Type for dynamic universe -->
        <mds-row
          v-if="this.universeTypes == 'Dynamic'"
          class="view-universe-list"
        >
          <mds-col :cols="3" id="entityLevel">Entity Level</mds-col>
          <mds-col :cols="8" id="entityLevelData">{{
            currentEntryLevel
          }}</mds-col>
        </mds-row>
      </mds-layout-grid>
      <div
        v-if="this.universeTypes == 'Dynamic'"
        class="view-universe-list-group-border"
      ></div>

      <!-- HTML for dynamic list  -->
      <template v-if="this.universeTypes == 'Dynamic'">
        <mds-layout-grid
          v-for="(dynamicuniverse, index) in showAddedCriteria"
          :key="index"
          class="view-universe-list"
        >
        <mds-row>
          <mds-col :cols="3" >{{
            dynamicuniverse.Title
          }}</mds-col>
          <mds-col :cols="8">
            <span
              v-for="(showselectedValue, index) in dynamicuniverse.Value"
              :key="index"
            >
              <span v-if="dynamicuniverse.OperatorCondition !== 'Between'">{{
                showselectedValue.text
              }}</span>
              <span
                v-if="
                  dynamicuniverse.OperatorCondition !== 'Between' &&
                    index + 1 < dynamicuniverse.Value.length
                "
                >,
              </span>
              <span v-if="dynamicuniverse.OperatorCondition === 'Between'">
                {{ betweenDateFrom }} To {{ betweenDateTo }}
              </span>
            </span>
          </mds-col>
          </mds-row>
          
          <mds-row
            v-if="index + 1 < showAddedCriteria.length"
            class="view-universe-list-group-border"
          ></mds-row>
        </mds-layout-grid>
      </template>

      <!-- HTML for Static list -->
      <template v-if="this.universeTypes == 'Static'">
        <mds-list-group
          class="view-universe-list"
          v-for="(staticuniverse, index) in selectedEntityNameRow"
          :key="index"
        >
          <span>
            {{ staticuniverse.entityname }}
          </span>
          <div
            v-if="index + 1 < selectedEntityNameRow.length"
            class="view-universe-list-group-border"
          ></div>
        </mds-list-group>
      </template>
    </div>
    <loader-component v-if="showLoader"></loader-component>
    <notification-component
        v-if="showNotification"
        :notificationMessage="notificationMessage"
        :notificationType="notificationType"
    ></notification-component>
  </div>
</template>

<script>
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { mapActions, mapGetters } from "vuex";
import { universeMixins } from "../../mixins/universeMixins.js";
import { MdsListGroup } from "@mds/list-group";
import NotificationComponent from "../ui_component/notificationComponent.vue";

export default {
  name: "ReportingViewUniverse",
  components: {
    LoaderComponent,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsListGroup,
    NotificationComponent
  },
  mixins: [universeMixins],
  data() {
    return {
      showLoader: true,
      viewUniverseName: "",
      universeTypes: "",
      editUniverse: [],
    };
  },

  async mounted() {
    this.editUniverse = {
      type: await this.$route.query.type,
      listid: await this.$route.query.listid,
      listdescription: await this.$route.query.name,
      iseditable: await this.$route.query.iseditable
    };
    this.viewUniverseName = await this.$route.query.name,
    this.universeTypes = await this.$route.query.type;
    await this.editCheckType();
    await this.fetchEntityTypes();
    this.showLoader = false;
  },

  computed: {
    ...mapGetters("bulkEdit", ["getEntities"]),
    ...mapGetters("entity", ["getEntityTypes"]),
    ...mapGetters("reporting", [
      "getDynamicDataPoint",
      "getSaveUniverse",
      "getEntity",
      "getEditUniverseList",
    ]),
  },
  methods: {
    ...mapActions("bulkEdit", ["getEntityList"]),
    ...mapActions("entity", ["fetchEntityTypes", "fetchDropdownListEntity"]),
    ...mapActions("reporting", [
      "fetchDynamicDataPoint",
      "fetchSaveUniverse",
      "fetchEntityList",
      "fetchEditUniverseList",
    ]),
    getRouteDetails(url) {
      const routeDetails = this.$router.resolve({
        path: url,
      });
      return routeDetails.resolved.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.view-universe {
  padding: 16px 16px 0 16px;
}

.view-universe-router {
  margin-left: $mds-space-half-x;
}

.view-universe-heading {
  margin-top: $mds-space-2-x;
  margin-bottom: $mds-space-2-x;
  height: 36px;
  left: 16px;
  top: 50px;
  font-style: normal;
  font-weight: 250;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.view-universe-name-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.view-universe-name {
  margin-top: 14px;
  margin-bottom: 16px;
}

.view-universe-border {
  border-bottom: 2px solid #333333;
  height: 0px;
}

.universe-selected-data-type-heading {
  padding-left: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: $mds-space-1-and-a-half-x;
}

.universe-criteria-name-column {
  padding-left: 0px;
}

.universe-criteria-column {
  padding-left: 0px;
}

.view-universe-margin {
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 14px;
}

.view-dynamic-universe-datapoint {
  width: 310px;
}

.view-dynamic-universe-datapoint-values {
  margin-left: $mds-space-1-and-a-half-x;
}

.view-universe-list {
  padding-top: 4px;
  padding-bottom: 4px;
}

.view-universe-list-group-border {
  border-bottom: 1px solid #e5e5e5;
}

.mds-layout-grid__col___VueMDS3Demo.mds-layout-grid__col--8___VueMDS3Demo {
    padding: 6px;
}
#entityLevel{
  padding: 0;
}
#entityLevelData{
  margin-left: -1px;
  padding: 0;
}
</style>
